import React from 'react';
import Layout from '../components/layout/layout';
import { TopSectionContact } from '../components/topsection';
import { Employees } from '../components/employee';
import SEO from '../components/seo'

const ContactPage = () => (
  <Layout>
    <SEO title={'Kontakt oss'} />
    <TopSectionContact
      title='Kontakt oss'
      tel='936 22 344'
      email='hei@caulis.no'
    />
    <Employees />
  </Layout>
);

export default ContactPage;
