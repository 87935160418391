import React from 'react';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Wrapper from '../layout/wrapper';
import './employee.scss';

export function SingleEmployee({ name, title, email, tel, img, id }) {
  // Split phone numbers in three groups
  const phone = [
    tel.slice(0, 3) + ' ' + tel.slice(3, 5) + ' ' + tel.slice(5, 8),
  ];

  return (
    <article
      className='employee-single'
      key={id}
      itemscope
      itemtype='https://schema.org/Person'
    >
      <Image
        className='employee-photo'
        fluid={img}
        placeholderClassName='employee-photo--placeholder'
        fadeIn={false}
      />
      <p className='employee-name' itemprop='name'>
        {name}
      </p>
      <p className='employee-title' itemprop='jobTitle'>
        <span className='circle'></span>
        {title}
      </p>
      <div className='employee-contact-information'>
        <a
          className='employee-ci-item link'
          itemprop='telephone'
          href={'mailto:' + email}
        >
          {email}
        </a>
        <a
          className='employee-ci-item link'
          itemprop='email'
          href={'tel:+47' + tel}
        >
          {phone}
        </a>
      </div>
    </article>
  );
}

export function Employees() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(id: { eq: "cG9zdDoxOTg=" }) {
        employeeLayout {
          layout {
            item {
              ... on WpEmployee {
                id
                name: title
                info: employeeFields {
                  cinfo: contactinformation {
                    email
                    tel
                  }
                  title
                  image {
                    lf: localFile {
                      cis: childImageSharp {
                        fl: fluid(toFormat: WEBP, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section id='employees'>
      <Wrapper center className='no-padding-mobile'>
        <h2>Vi er Caulis</h2>
        <div className='employee-grid'>
          {data.wpPage.employeeLayout.layout.map((person) => (
            // If employee is hidden or deleted, don't return him/her
            person.item != null &&
              <SingleEmployee
                img={person.item.info.image.lf.cis.fl}
                name={person.item.name}
                title={person.item.info.title}
                email={person.item.info.cinfo.email}
                tel={person.item.info.cinfo.tel}
                id={person.item.id}
              />
          ))}
        </div>
      </Wrapper>
    </section>
  );
}
